const topicSlug = {
  postupKOdstranenipochybnosti: 'postup-k-odstraneni-pochybnosti/',
  danovaKontrola: 'danova-kontrola/'
}

export const Content = {
  postupKOdstranenipochybnosti: {
    title: 'Postup k odstranění pochybností',
    slug: topicSlug.postupKOdstranenipochybnosti,
    description: `Postup k odstranění pochybností by měl být časově i administrativně méně náročnější než daňová kontrola. Správce daně by ho měl využívat v případech...`
  },
  danovaKontrola: {
    title: 'Daňová kontrola',
    slug: topicSlug.danovaKontrola,
    description: `Daňová kontrola je zahajována doručením oznámení o zahájení daňové kontroly, v němž je předmět a rozsah kontroly. Během daňové kontroly musí daňový subjekt plnit povinnosti dané daňovým řádem, např. musí:
`
  }
}
export default Content
