import React from 'react'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../components/Accordion/Accordion'
import AccordionTemplate from '../../components/Services/AccordionTemplate'
import Content from '../../../content/accordion/kontroly-z-pohledu-fu'
import { graphql } from 'gatsby'
import { H2Blue, UnderlineYellow } from '../../components/Typography'
const Kontrolyzpohledufu = ({ data: { page } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />

      <div className="Paragraph--Wrapper container">
        <p>{frontmatter.description}</p>
        <H2Blue>Kontrolní postupy správce daně</H2Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '5.8rem' }}>
          Správce daně používá při kontrolní činnosti dva hlavní nástroje,
          kterými jsou postup k odstranění pochybností a daňová kontrola.
          Zatímco postup k odstranění pochybností by měl být časově méně
          náročnější, jsou kontroly prováděné ze strany správce daně pro mnoho
          firem náročné jak po administrativní, tak časové stránce. Pokud
          hledáte cestu, jak kontroly zvládnout efektivně, můžeme vám pomoci.
        </p>
        <Accordion
          items={[
            {
              title: Content.postupKOdstranenipochybnosti.title,
              description: (
                <AccordionTemplate {...Content.postupKOdstranenipochybnosti} />
              )
            },
            {
              title: Content.danovaKontrola.title,
              description: <AccordionTemplate {...Content.danovaKontrola} />
            }
          ]}
        />
      </div>
      <br />
      <br />
    </Layout>
  )
}

export default Kontrolyzpohledufu

export const pageQuery = graphql`
  query Kontrolyzpohledufu {
    page: markdownRemark(
      frontmatter: { slug: { eq: "kontroly-z-pohledu-fu" } }
    ) {
      ...Meta
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
